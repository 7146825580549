"use client";

import config from "@/data/config.mts";
import { allAbouts } from "contentlayer/generated";
import Image from "next/image";
import CustomLink from "./CustomLink";

export default function AuthorBox() {
  const about = allAbouts[0];
  return (
    <>
      <div>
        <div className="my-6 rounded-lg border-2 border-yellow bg-black-500 px-10 py-6 text-left hover:shadow-md hover:shadow-yellow hover:brightness-110">
          <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
            <div className="flex flex-col items-center space-x-2 pt-4">
              {config.author.avatar && (
                <Image
                  src={config.author.avatar}
                  alt="Luca Cavallin"
                  width={192}
                  height={192}
                  className="size-24 rounded-full"
                  placeholder="blur"
                  blurDataURL={config.author.avatar_base64}
                  priority
                />
              )}
              <h2 className="pb-2 pt-4 text-2xl font-bold leading-8 tracking-tight">
                {config.author.name}
              </h2>
            </div>
            <div className="prose max-w-none py-1 xl:col-span-2">
              <h2 className="mb-0 text-2xl font-bold leading-8 tracking-tight">
                Hello, Luca here!
              </h2>
              <div>
                <p>
                  {about.short} Read more on the <CustomLink href="/about">about</CustomLink> page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
